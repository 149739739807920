.modal {
  padding: 32px;
  gap: 16px;
  border: 1px solid #dae1fb;

  .header {
    padding: 0;
    font-size: 24px;
    line-height: 32px;
    color: #232B39;
  }

  .body {
    padding: 0;
    width: 100%;
    gap: 16px;
    display: flex;
    flex-direction: column;

    .addButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 36px;
      border-radius: 8px 8px 8px 8px;
      transition: ease-out 0.2s;
      font-weight: 400;
      color: var(--chakra-colors-blueLink) !important;
      border: 1px solid var(--chakra-colors-line) !important;
      background-color: white !important;

      button {
        cursor: pointer;
        border: none;
        outline: none;
        width: 90%;
        height: 100%;
      }

      &:hover {
        border: 1px dashed var(--chakra-colors-blueLink) !important;
        background: var(--chakra-colors-bgMiddle) !important;
      }
    }

    .formBox {
      gap: 12px;
      flex-direction: column;

      .crossIcon {
        padding: 4px;
        border-radius: 50%;
        color: var(--chakra-colors-darkGrey);
        transition: ease-out 0.2s;
        cursor: pointer;

        &:hover {
          background: var(--chakra-colors-defaultGrey);
          color: var(--chakra-colors-mainPurple);
        }
      }

      .label {
        font-size: 15px;
        line-height: 20px;
        color: #232b39 !important;
      }

      .error {
        font-size: 13px;
        color: var(--chakra-colors-mainRed);
      }

      .colors {
        display: flex;

        .colorBlock {
          width: 32px;
          height: 32px;
          justify-content: center;
          align-items: center;
          border-radius: 50px;
          transition: border 0.1s ease;

          &:hover {
            border: 2px solid grey;
          }

          .color {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            cursor: pointer;
          }
        }

        .selected {
          border: 2px solid grey;
        }
      }
    }
  }

  .footer {
    margin-top: 16px;
    padding: 0;
    display: flex;
    justify-content: space-between;
  }
}

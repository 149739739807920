.settingsBlock {
  border-radius: 12px;
  border: 1px solid #dce7fb;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  font-family: Lato, sans-serif;
  color: var(--New-Black, #232b39);
  justify-content: start;
  background-color: white;

  .header {
    align-self: stretch;
    width: 100%;
    gap: 16px;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    padding: 24px 24px 16px;
  }

  .lines {
    width: 100%;
    flex-direction: column;
    justify-content: start;

    .line {
      background-color: rgba(255, 255, 255, 1);
      display: flex;
      width: 100%;
      align-items: center;
      gap: 16px;
      white-space: nowrap;
      justify-content: start;
      padding: 16px 24px 16px 8px;
      border-bottom: 1px solid #F1F4F9;


      .lineName {
        align-self: stretch;
        font-size: 16px;
        line-height: 20px;
        width: 240px;
        margin: 0;
        padding: 0 16px;
        flex-direction: column;
        justify-content: center;
      }

      .discription {
        font-size: 15px;
        line-height: 20px;
        gap: 8px;

        .duration {
          width: 176px;
          padding: 8px 16px 8px 16px;
          background-color: #f7faff;
          border: 1px solid #dae1fb;
          border-radius: 8px;
          align-items: center;
        }

        .cost {
          width: 123px;
          padding: 8px 16px 8px 16px;
          background-color: #f7faff;
          border: 1px solid #dae1fb;
          border-radius: 8px;
          align-items: center;
        }
      }
    }
  }
}

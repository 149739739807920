.settingsBlock {
  border-radius: 12px;
  border: 1px solid #dce7fb;
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  font-family: Lato, sans-serif;
  color: var(--New-Black, #232b39);
  justify-content: start;
  background-color: white;

  .header {
    align-self: stretch;
    width: 100%;
    gap: 16px;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    padding: 24px 24px 16px;
  }

  .lines {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: start;

    .line {
      background-color: rgba(255, 255, 255, 1);
      display: flex;
      width: 100%;
      align-items: center;
      gap: 16px;
      white-space: nowrap;
      justify-content: start;
      padding: 16px 24px 16px 8px;

      .lineName {
        align-self: stretch;
        font-size: 16px;
        font-weight: 500;
        line-height: 1;
        width: 240px;
        margin: 0;
        padding: 8px 16px;
        flex-direction: column;
      }

      .addButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 36px;
        border-radius: 8px 8px 8px 8px;
        transition: ease-out 0.2s;
        font-weight: 400;
        color: var(--chakra-colors-blueLink) !important;
        border: 1px solid var(--chakra-colors-line) !important;
        background-color: white !important;

        button {
          cursor: pointer;
          border: none;
          outline: none;
          width: 90%;
          height: 100%;
        }

        &:hover {
          border: 1px dashed var(--chakra-colors-blueLink) !important;
          background: var(--chakra-colors-bgMiddle) !important;
        }
      }
    }
  }
}

.popoverBody::-webkit-scrollbar {
  display: none !important;
}

.errorTimeText {
  color: red;
  font-size: 14px;
}

.modal {
  padding: 32px;
  gap: 16px;
  width: 460px;
  flex-direction: column;

  .header {
    padding: 0;
    font-size: 24px;
    line-height: 32px;
    color: #232B39;
  }

  .body {
    padding: 0;
    width: 100%;
    gap: 16px;
    display: flex;
    flex-direction: column;

    .formBox {
      gap: 12px;
      flex-direction: column;

      .label {
        font-size: 15px;
        line-height: 20px;
        color: #232B39;
      }

      .input {
        border-radius: 8px;
        border: 1px solid #dae1fb;
        height: 36px;
        background-color: white;

        &:hover {
          border: 1px solid #325ee6;
        }

        &:focus {
          border: 1px solid #325ee6;
          box-shadow: none;
        }
      }

      .dateInput {
        border-radius: 8px;
        border: 1px solid #dae1fb;
        height: 36px;
        background-color: white;
        padding-left: 12px;
        font-size: 15px;

        &:hover {
          border: 1px solid #325ee6;
        }

        &:focus {
          border: 1px solid #325ee6;
          box-shadow: none;
        }
      }

      .color {
        min-width: 8px;
        min-height: 8px;
        border-radius: 50px;
      }

      .error {
        font-size: 13px;
        color: var(--chakra-colors-mainRed);
      }
    }
  }

  .footer {
    margin-top: 16px;
    padding: 0;
    display: flex;
    justify-content: space-between;
  }
}

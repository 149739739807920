.menu {
  background-color: var(--chakra-colors-defaultGrey);
  border-radius: 8px;
  transition: all 0.2s;

  .menuButton {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    transition: all 0.2s;
    text-decoration: underline;
    text-underline-offset: 2px;
    text-decoration-color: var(--chakra-colors-line);
    text-decoration-thickness: 2px;

    &:hover {
      text-decoration-color: var(--chakra-colors-mainPurple);
      color: var(--chakra-colors-mainPurple);
      background-color: var(--chakra-colors-defaultGrey);
    }

    &[data-active] {
      text-decoration-color: var(--chakra-colors-mainPurple);
      color: var(--chakra-colors-mainPurple);
      background-color: var(--chakra-colors-defaultGrey);
    }
  }
  &.intersection{
    outline: 1px solid red;
    border-radius: 5px;
    box-sizing: border-box;
    margin-bottom: 1px;
  }
}

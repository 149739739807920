.modalOpacity {
  opacity: 0 !important;
}

.modal {
  padding: 32px;
  gap: 16px;
  border: 1px solid #dae1fb;

  .header {
    padding: 0;
    font-size: 24px;
    line-height: 32px;
    color: #232B39;
  }

  .body {
    padding: 0;
    width: 100%;
    gap: 16px;
    display: flex;
    flex-direction: column;

    .formBox {
      gap: 12px;
      flex-direction: column;

      .label {
        font-size: 15px;
        line-height: 20px;
        color: #232B39;
      }

      .error {
        font-size: 13px;
        color: var(--chakra-colors-mainRed);
      }

      .colors {
        display: flex;

        .colorBlock {
          width: 32px;
          height: 32px;
          justify-content: center;
          align-items: center;
          border-radius: 50px;
          transition: border 0.1s ease;

          &:hover {
            border: 2px solid grey;
          }

          .color {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            cursor: pointer;
          }
        }

        .selected {
          border: 2px solid grey;
        }
      }
    }
  }

  .footer {
    margin-top: 16px;
    padding: 0;
    display: flex;
    justify-content: space-between;
  }
}

.main {
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  background-color: #f7faff;
  gap: 16px;

  .settingsBlock {
    border-radius: 12px;
    border: 1px solid #dce7fb;
    display: flex;
    width: 100%;
    flex-direction: column;
    overflow: hidden;
    font-family: Lato, sans-serif;
    color: var(--New-Black, #232b39);
    justify-content: start;
    background-color: white;

    .header {
      align-self: stretch;
      width: 100%;
      gap: 16px;
      font-size: 18px;
      font-weight: 700;
      line-height: 1;
      padding: 24px 24px 16px;
    }

    .lines {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: start;

      .line {
        background-color: rgba(255, 255, 255, 1);
        display: flex;
        width: 100%;
        align-items: center;
        gap: 16px;
        white-space: nowrap;
        justify-content: start;
        padding: 16px 24px 16px 8px;

        .lineName {
          align-self: stretch;
          font-size: 16px;
          font-weight: 500;
          line-height: 1;
          width: 240px;
          margin: auto 0;
          padding: 8px 16px 8px 8px;
        }

        .input {
          align-self: stretch;
          display: flex;
          align-items: center;
          gap: 8px;
          white-space: nowrap;
          justify-content: start;
          margin: auto 0;

          .inputText {
            min-width: 74px;
            align-items: center;
            border-radius: 8px;
            border: 1px solid var(--New-Line, #dae1fb);
            background-color: #fff;
            align-self: stretch;
            display: flex;
            min-height: 36px;
            width: 150px;
            gap: 4px;
            overflow: hidden;
            font-size: 15px;
            font-weight: 400;
            line-height: 1;
            justify-content: start;
            margin: auto 0;
            padding: 0 8px;
          }
        }
      }
    }
  }
}

.img {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  align-self: stretch;
  margin: auto 0;
}
.settingsBlock {
  .crossV2 {
    align-self: stretch;
    display: flex;
    width: 20px;
    height: 20px;
    margin: auto 0;
  }
  .text4 {
    align-self: stretch;
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--New-Black, #232b39);
    white-space: nowrap;
    justify-content: start;
    width: 240px;
    margin: auto 0;
    padding: 8px 16px;
    font:
      500 16px/1 Lato,
      sans-serif;
  }
  @media (max-width: 991px) {
    .text4 {
      white-space: initial;
    }
  }
  .v {
    align-self: stretch;
    margin: auto 0;
  }
  .payload {
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--New-Line, #dae1fb);
    background: var(--New-BG-light-blue, #f7faff);
    background-color: var(--New-BG-light-blue, #f7faff);
    align-self: stretch;
    display: flex;
    min-width: 240px;
    gap: 4px;
    color: var(--New-Black, #232b39);
    white-space: nowrap;
    text-align: center;
    justify-content: start;
    width: 307px;
    margin: auto 0;
    padding: 8px 16px;
    font:
      400 15px/1 Lato,
      sans-serif;
  }
  @media (max-width: 991px) {
    .payload {
      white-space: initial;
    }
  }
  .title5 {
    align-self: stretch;
    gap: 4px;
    margin: auto 0;
  }
  @media (max-width: 991px) {
    .title5 {
      white-space: initial;
    }
  }
  .rightDelete {
    border-radius: 0px 4px 4px 0px;
    background-color: rgba(255, 255, 255, 1);
    align-self: stretch;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: start;
    width: 36px;
    height: 36px;
    margin: auto 0;
    padding: 6px;
  }
  .img3 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
  }
  .line4 {
    align-items: center;
    border-top: 1px solid var(--New-Grey, #f1f4f9);
    display: flex;
    width: 100%;
    gap: 16px;
    justify-content: start;
    flex-wrap: wrap;
    padding: 16px 24px 16px 8px;
  }
  @media (max-width: 991px) {
    .line4 {
      max-width: 100%;
      padding-right: 20px;
    }
  }
  .text5 {
    align-self: stretch;
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--New-Black, #232b39);
    white-space: nowrap;
    justify-content: start;
    width: 240px;
    margin: auto 0;
    padding: 8px 16px;
    font:
      500 16px/1 Lato,
      sans-serif;
  }
  @media (max-width: 991px) {
    .text5 {
      white-space: initial;
    }
  }
  .div5 {
    background-color: var(--Color-Yellow, #ffc300);
    border-radius: 50%;
    align-self: stretch;
    display: flex;
    width: 8px;
    height: 8px;
    fill: var(--Color-Yellow, #ffc300);
    margin: auto 0;
  }
  .a {
    align-self: stretch;
    margin: auto 0;
  }
  .img4 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
  }
  .buttonPrimery {
    align-self: stretch;
    border-radius: 8px;
    background: var(--New-Main-Purple, #6d5bf7);
    background-color: var(--New-Main-Purple, #6d5bf7);
    gap: 4px;
    margin: auto 0;
    padding: 8px 24px;
  }
  @media (max-width: 991px) {
    .buttonPrimery {
      padding: 0 20px;
    }
  }
  .services {
    border-radius: 12px;
    border: 1px solid var(--New-Light-deep-blue, #dce7fb);
    background-color: #fff;
    display: flex;
    margin-top: 16px;
    width: 100%;
    max-width: 1002px;
    flex-direction: column;
    overflow: hidden;
    justify-content: start;
  }
  @media (max-width: 991px) {
    .services {
      max-width: 100%;
    }
  }
  .header3 {
    font-feature-settings:
      "liga" off,
      "clig" off;
    align-self: stretch;
    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    gap: 16px;
    color: var(--New-Black, #232b39);
    white-space: nowrap;
    padding: 24px 24px 16px;
    font:
      700 18px/1 Lato,
      sans-serif;
  }
  @media (max-width: 991px) {
    .header3 {
      white-space: initial;
      padding: 0 20px;
    }
  }
  .line5 {
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    width: 100%;
    align-items: center;
    gap: 16px;
    justify-content: start;
    flex-wrap: wrap;
    padding: 14px 24px 14px 8px;
  }
  @media (max-width: 991px) {
    .line5 {
      max-width: 100%;
      padding-right: 20px;
    }
  }
  .text6 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    font-family: Lato, sans-serif;
    justify-content: start;
    width: 240px;
    margin: auto 0;
    padding: 0 16px;
  }
  .stri {
    color: var(--New-Black, #232b39);
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
  }
  .dopus {
    color: var(--New-Dark-grey, #8592a3);
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    margin-top: 4px;
  }
  .inputs {
    align-self: stretch;
    display: flex;
    min-width: 240px;
    align-items: center;
    gap: 8px;
    color: var(--New-Black, #232b39);
    text-align: center;
    justify-content: start;
    margin: auto 0;
    font:
      400 15px/1 Lato,
      sans-serif;
  }
  .payload2 {
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--New-Line, #dae1fb);
    background: var(--New-BG-light-blue, #f7faff);
    background-color: var(--New-BG-light-blue, #f7faff);
    align-self: stretch;
    display: flex;
    gap: 4px;
    justify-content: start;
    width: 123px;
    margin: auto 0;
    padding: 8px 16px;
  }
  .title52 {
    align-self: stretch;
    gap: 4px;
    margin: auto 0;
  }
  .payload3 {
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--New-Line, #dae1fb);
    background: var(--New-BG-light-blue, #f7faff);
    background-color: var(--New-BG-light-blue, #f7faff);
    align-self: stretch;
    display: flex;
    gap: 4px;
    justify-content: start;
    width: 176px;
    margin: auto 0;
    padding: 8px 16px;
  }
  .title53 {
    align-self: stretch;
    gap: 4px;
    margin: auto 0;
  }
  .img5 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
  }
  .line6 {
    align-items: center;
    border-top: 1px solid var(--New-Grey, #f1f4f9);
    display: flex;
    width: 100%;
    gap: 16px;
    justify-content: start;
    flex-wrap: wrap;
    padding: 14px 24px 14px 8px;
  }
  @media (max-width: 991px) {
    .line6 {
      max-width: 100%;
      padding-right: 20px;
    }
  }
  .text7 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    font-family: Lato, sans-serif;
    justify-content: start;
    width: 240px;
    margin: auto 0;
    padding: 0 16px;
  }
  .мужская-стрижка {
    color: var(--New-Black, #232b39);
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
  }
  .payload4 {
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--New-Line, #dae1fb);
    background: var(--New-BG-light-blue, #f7faff);
    background-color: var(--New-BG-light-blue, #f7faff);
    align-self: stretch;
    display: flex;
    gap: 4px;
    justify-content: start;
    width: 123px;
    margin: auto 0;
    padding: 8px 16px;
  }
  .title54 {
    align-self: stretch;
    gap: 4px;
    margin: auto 0;
  }
  .payload5 {
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--New-Line, #dae1fb);
    background: var(--New-BG-light-blue, #f7faff);
    background-color: var(--New-BG-light-blue, #f7faff);
    align-self: stretch;
    display: flex;
    gap: 4px;
    justify-content: start;
    width: 176px;
    margin: auto 0;
    padding: 8px 16px;
  }
  .title55 {
    align-self: stretch;
    gap: 4px;
    margin: auto 0;
  }
  .img6 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
  }
  .options {
    border-radius: 12px;
    border: 1px solid var(--New-Light-deep-blue, #dce7fb);
    background-color: #fff;
    display: flex;
    margin-top: 16px;
    width: 100%;
    max-width: 1002px;
    flex-direction: column;
    overflow: hidden;
    justify-content: start;
  }
  @media (max-width: 991px) {
    .options {
      max-width: 100%;
    }
  }
  .header4 {
    font-feature-settings:
      "liga" off,
      "clig" off;
    align-self: stretch;
    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    gap: 16px;
    color: var(--New-Black, #232b39);
    white-space: nowrap;
    padding: 24px 24px 16px;
    font:
      700 18px/1 Lato,
      sans-serif;
  }
  @media (max-width: 991px) {
    .header4 {
      white-space: initial;
      padding: 0 20px;
    }
  }
  .line7 {
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    width: 100%;
    align-items: center;
    gap: 16px;
    justify-content: start;
    flex-wrap: wrap;
    padding: 14px 24px 14px 8px;
  }
  @media (max-width: 991px) {
    .line7 {
      max-width: 100%;
      padding-right: 20px;
    }
  }
  .text8 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    font-family: Lato, sans-serif;
    justify-content: start;
    width: 240px;
    margin: auto 0;
    padding: 0 16px;
  }
  .payload6 {
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--New-Line, #dae1fb);
    background: var(--New-BG-light-blue, #f7faff);
    background-color: var(--New-BG-light-blue, #f7faff);
    align-self: stretch;
    display: flex;
    gap: 4px;
    justify-content: start;
    width: 123px;
    margin: auto 0;
    padding: 8px 16px;
  }
  .title56 {
    align-self: stretch;
    gap: 4px;
    margin: auto 0;
  }
  .payload7 {
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--New-Line, #dae1fb);
    background: var(--New-BG-light-blue, #f7faff);
    background-color: var(--New-BG-light-blue, #f7faff);
    align-self: stretch;
    display: flex;
    gap: 4px;
    justify-content: start;
    width: 176px;
    margin: auto 0;
    padding: 8px 16px;
  }
  .title57 {
    align-self: stretch;
    gap: 4px;
    margin: auto 0;
  }
  .img7 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
  }
  .line8 {
    align-items: center;
    border-top: 1px solid var(--New-Grey, #f1f4f9);
    display: flex;
    width: 100%;
    gap: 16px;
    justify-content: start;
    flex-wrap: wrap;
    padding: 14px 24px 14px 8px;
  }
  @media (max-width: 991px) {
    .line8 {
      max-width: 100%;
      padding-right: 20px;
    }
  }
  .text9 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    font-family: Lato, sans-serif;
    justify-content: start;
    width: 240px;
    margin: auto 0;
    padding: 0 16px;
  }
  .payload8 {
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--New-Line, #dae1fb);
    background: var(--New-BG-light-blue, #f7faff);
    background-color: var(--New-BG-light-blue, #f7faff);
    align-self: stretch;
    display: flex;
    gap: 4px;
    justify-content: start;
    width: 123px;
    margin: auto 0;
    padding: 8px 16px;
  }
  .title58 {
    align-self: stretch;
    gap: 4px;
    margin: auto 0;
  }
  .payload9 {
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--New-Line, #dae1fb);
    background: var(--New-BG-light-blue, #f7faff);
    background-color: var(--New-BG-light-blue, #f7faff);
    align-self: stretch;
    display: flex;
    gap: 4px;
    justify-content: start;
    width: 176px;
    margin: auto 0;
    padding: 8px 16px;
  }
  .title59 {
    align-self: stretch;
    gap: 4px;
    margin: auto 0;
  }
  .img8 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
  }
}

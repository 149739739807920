.formBox {
  gap: 12px;
  flex-direction: column;

  .crossIcon {
    padding: 4px;
    border-radius: 50%;
    color: var(--chakra-colors-darkGrey);
    transition: ease-out 0.2s;
    cursor: pointer;

    &:hover {
      background: var(--chakra-colors-defaultGrey);
      color: var(--chakra-colors-mainPurple);
    }
  }

  .label {
    font-size: 15px;
    line-height: 20px;
    color: #232B39;
  }

  & > input::placeholder {
    font-size: 15px;
    line-height: 20px;
    color: #8592A3;
  }

  .error {
    font-size: 13px;
    color: var(--chakra-colors-mainRed);
  }

  .colors {
    display: flex;

    .colorBlock {
      width: 32px;
      height: 32px;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      transition: border 0.1s ease;

      &:hover {
        border: 2px solid grey;
      }

      .color {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        cursor: pointer;
      }
    }

    .selected {
      border: 2px solid grey;
    }
  }
}

.container {
  width: 100%;
  padding: 10px 20px;
  background-color: #eff5fe;
  justify-content: space-between;

  .headName {
    font-size: 20px;
    font-weight: 500;
    color: #3e5e95;
  }
}
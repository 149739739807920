.container {
  cursor: pointer;
  width: 32px;
  height: 32px;
  color: var(--chakra-colors-midDeepBlue);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: all 0.2s;

  &:hover {
    color: var(--chakra-colors-darkPurple);
    background-color: var(--chakra-colors-lightDeepBlue);
  }
}

.css-7y5car {
  overflow: hidden;
}

.css-1edfpdg-MuiTypography-root {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.MuiListItemText-root {
  max-width: 200px;
}
.headerContainer {
  text-align: center;
  top: 22px;
  z-index: 100;
  background-color: #f7faff;
  justify-content: space-between;
  position: absolute;
  right: 36px;
  gap: 16px;
}
